import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Button, { ButtonType } from 'components/Core/Button/Button'
import { NotificationsManager } from 'components/Core/Feedback/Notifications/NotificationsManager'
import { IconType } from 'components/Core/Icon/IconType'
import Column from 'components/Core/Layout/Column/Column'
import ModalView from 'components/Core/Modal/ModalView'
import SectionTitle from 'components/Core/Text/SectionTitle'
import Paragraph from 'components/Core/Typography/Paragraph'
import { useDeleteTestResultMutation } from 'graphql/v2/generated/graphql'
import React from 'react'

interface Props {
    onRequestClose: () => void
    onSuccess?: () => void
    testResultId: string
}

export const ParticipantsLearningNeedsTestDeleteModal: React.FC<Props> = (props: Props) => {
    const { onRequestClose: onClose, testResultId } = props
    const { i18n } = useLingui()

    const [deleteTestResultMutation, { loading }] = useDeleteTestResultMutation({
        update(cache) {
            cache.evict({ fieldName: 'participation' })
            cache.evict({ fieldName: 'participations' })
        },
    })

    return (
        <ModalView
            onClose={onClose}
            ContentComponent={
                <Column spacing={6}>
                    <SectionTitle title={i18n._(t`Resultaat verwijderen`)} heading="H4" />
                    <Paragraph>{i18n._(t`Weet je zeker dat je het resultaat wilt verwijderen?`)}</Paragraph>
                </Column>
            }
            BottomComponent={
                <>
                    <Button disabled={loading} type={ButtonType.secondary} onClick={onClose}>
                        {i18n._(t`Annuleren`)}
                    </Button>
                    <Button
                        danger={true}
                        type={ButtonType.primary}
                        icon={IconType.delete}
                        onClick={onSubmit}
                        loading={loading}
                    >
                        {i18n._(t`Verwijderen`)}
                    </Button>
                </>
            }
        />
    )

    async function onSubmit() {
        try {
            await deleteTestResultMutation({
                variables: {
                    input: {
                        id: testResultId,
                    },
                },
            })

            NotificationsManager.success(
                i18n._(t`Resultaat is verwijderd`),
                i18n._(t`Je wordt teruggestuurd naar het overzicht`)
            )

            if (props.onSuccess) {
                props.onSuccess()
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.data) {
                NotificationsManager.error(i18n._(t`Actie mislukt`), i18n._(t`Er is een onverwachte fout opgetreden`))
            }
        }
    }
}
