import { i18n } from '@lingui/core'

import { ORGANIZATION_SLUG_PARAM } from 'routes/routes'
import { taalhuisRoutes } from 'routes/taalhuis/taalhuisRoutes'

export enum Tabs {
    participants = 'participants',
    registrations = 'registrations',
}

export const tabPaths = (organizationSlug: string = ORGANIZATION_SLUG_PARAM) => ({
    [Tabs.participants]: taalhuisRoutes(organizationSlug).participants.index,
    [Tabs.registrations]: taalhuisRoutes(organizationSlug).participants.registrations.index,
})

export const tabTranslations = {
    [Tabs.participants]: i18n._(`Deelnemers`),
    [Tabs.registrations]: i18n._(`Aanmeldingen`),
}

export enum ReadDetailTabs {
    intake = 'read',
    registration = 'registration',
    contactMoments = 'contactMoments',
    learningNeeds = 'learningNeeds',
    documents = 'documents',
}

export const readDetailTabPaths = (participantId: string, organizationSlug: string = ORGANIZATION_SLUG_PARAM) => ({
    [ReadDetailTabs.intake]: taalhuisRoutes(organizationSlug).participants.detail(participantId).data.index,
    [ReadDetailTabs.registration]:
        taalhuisRoutes(organizationSlug).participants.detail(participantId).data.registration,
    [ReadDetailTabs.contactMoments]: taalhuisRoutes(organizationSlug).participants.detail(participantId).data.dossier,
    [ReadDetailTabs.learningNeeds]:
        taalhuisRoutes(organizationSlug).participants.detail(participantId).data.learningNeeds.index,
    [ReadDetailTabs.documents]: taalhuisRoutes(organizationSlug).participants.detail(participantId).data.documents,
})

export const readDetailTabsTranslations = {
    [ReadDetailTabs.intake]: i18n._(`Intake`),
    [ReadDetailTabs.registration]: i18n._(`Aanmelding`),
    [ReadDetailTabs.contactMoments]: i18n._(`Dossier`),
    [ReadDetailTabs.learningNeeds]: i18n._(`Leervragen`),
    [ReadDetailTabs.documents]: i18n._(`Documenten`),
}
