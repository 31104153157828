import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import DateInput from 'components/Core/DataEntry/DateInput'
import Field from 'components/Core/Field/Field'
import Column from 'components/Core/Layout/Column/Column'
import Row from 'components/Core/Layout/Row/Row'
import styles from '../../../Bisc/Reports/Fieldsets/PeriodFieldset.module.scss'

interface Props {
    prefillData?: ProviderPeriodFieldsetPrefillData
}

export interface ProviderPeriodFieldsetFormModel {
    periodFrom?: string
    periodTo?: string
}
export interface ProviderPeriodFieldsetPrefillData {
    periodFrom?: string
    periodTo?: string
}

export const ProviderPeriodFieldset: React.FunctionComponent<Props> = props => {
    const { i18n } = useLingui()

    return (
        <Column spacing={4}>
            <Row spacing={5}>
                <Row spacing={5} className={styles.periodWrapper}>
                    <div className={styles.periodFieldWithoutSelect}>
                        <Field label={i18n._(t`Periode van`)} grow={true}>
                            <DateInput name={'periodFrom'} placeholder={i18n._(t`DD/MM/YYYY`)} grow={true} />
                        </Field>
                    </div>
                    <div className={styles.periodFieldWithoutSelect}>
                        <Field label={i18n._(t`periode tot`)} grow={true}>
                            <DateInput name={'periodTo'} placeholder={i18n._(t`DD/MM/YYYY`)} grow={true} />
                        </Field>
                    </div>
                </Row>
            </Row>
        </Column>
    )
}
