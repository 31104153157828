import { TaalhuisCoworkersInformationFieldsetModel } from 'components/fieldsets/taalhuis/TaalhuisCoworkersInformationFieldset'
import { TaalhuisInformationFieldsetModel } from 'components/fieldsets/taalhuis/TaalhuisInformationFieldset'
import { EmployeeQuery } from 'graphql/v2/generated/graphql'

export function getMappedTaalhuisFormFields(formData: TaalhuisInformationFieldsetModel) {
    const address = {
        street: formData['address.street'] ?? '',
        houseNumber: formData['address.houseNumber'] ?? '',
        houseNumberSuffix: formData['address.houseNumberSuffix'] ?? '',
        postalCode: formData['address.postalCode'] ?? '',
        locality: formData['address.locality'] ?? '',
        country: 'NL',
        name: formData['name'] ?? '',
    }

    return {
        name: formData['name'] ?? '',
        address,
        telephone: formData['telephone'] ?? '',
        email: formData['email'] ?? '',
        postalCodes: formData.postalCodes?.map(c => parseInt(c)) ?? [],
    }
}

export function getMappedTaalhuisCoworkerFormFields(
    formData: TaalhuisCoworkersInformationFieldsetModel,
    languageHouseId: string,
    defaultTaalhuisCoworker?: any | EmployeeQuery['employee']
): any {
    const person = {
        id: defaultTaalhuisCoworker?.person.id,
        givenName: formData['person.givenName'] ?? '',
        additionalName: formData['person.additionalName'] ?? '',
        familyName: formData['person.familyName'] ?? '',
        telephone: formData['person.telephone'] ?? '',
        email: formData['person.email'] ?? '',
    }

    return {
        // id: defaultTaalhuisCoworker?.id,
        person,
        organization: languageHouseId,
        role: formData.role ?? undefined,
        teams: formData.team ? [formData.team] : undefined,
    }
}
