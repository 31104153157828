import React, { useContext } from 'react'
import { Switch, Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { NotFoundView } from '../../../../Generic/NotFoundView'
import { TaalhuisDetailDataView } from './Data/TaalhuisDetailDataView'
import { routes } from 'routes/routes'
import { TaalhuisDetailUpdateView } from './Data/TaalhuisDetailUpdateView'
import { CoworkersView } from './Coworkers/CoworkersView'
import { BiscTaalhuizenDetailRouteParams } from 'routes/bisc/biscRoutes'
import { IntakeSettingsReadView } from './IntakeSettings/IntakeSettingsReadView'
import { IntakeSettingsUpdateView } from './IntakeSettings/IntakeSettingsUpdateView'
import { SessionContext } from 'components/Providers/SessionProvider/context'
import { TaalhuisProvidersView } from './Providers/TaalhuisProvidersView'
import { TaalhuisProvidersEditView } from './Providers/TaalhuisProvidersEditView'

interface Props extends RouteComponentProps<BiscTaalhuizenDetailRouteParams> {}

const TaalhuizenDetailView: React.FunctionComponent<Props> = props => {
    const sessionContext = useContext(SessionContext)
    const basePath = routes.authorized.bisc().taalhuizen.detail()

    return (
        <Switch>
            <Redirect
                path={basePath.index}
                exact={true}
                to={routes.authorized.bisc(sessionContext.organizationSlug).taalhuizen.detail().data.index}
            />
            <Route path={basePath.data.index} exact={true} component={TaalhuisDetailDataView} />
            <Route path={basePath.data.update} exact={true} component={TaalhuisDetailUpdateView} />
            <Route path={basePath.coworkers.index} component={CoworkersView} />
            <Route path={basePath.intakeSettings.index} exact={true} component={IntakeSettingsReadView} />
            <Route path={basePath.intakeSettings.update} exact={true} component={IntakeSettingsUpdateView} />
            <Route path={basePath.providers.view} exact={true} component={TaalhuisProvidersView} />
            <Route path={basePath.providers.update} exact={true} component={TaalhuisProvidersEditView} />
            <Route component={NotFoundView} />
        </Switch>
    )
}

export default TaalhuizenDetailView
