import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Button, { ButtonType } from 'components/Core/Button/Button'
import { NotificationsManager } from 'components/Core/Feedback/Notifications/NotificationsManager'
import { IconType } from 'components/Core/Icon/IconType'
import Column from 'components/Core/Layout/Column/Column'
import ModalView from 'components/Core/Modal/ModalView'
import SectionTitle from 'components/Core/Text/SectionTitle'
import Paragraph from 'components/Core/Typography/Paragraph'
import { useDeleteStudentMutation } from 'graphql/v2/generated/graphql'

interface Props {
    studentId: string
    studentName: string
    onClose: () => void
    onDelete?: () => void
    onDeleteSuccess: () => void
}

export const DeleteParticipantModal: React.FC<Props> = props => {
    const { i18n } = useLingui()

    const { onClose, onDeleteSuccess, studentName, studentId } = props

    const [deleteStudentMutation, { loading }] = useDeleteStudentMutation({
        update(cache) {
            cache.evict({ fieldName: 'learningNeed' })
            cache.evict({ fieldName: 'learningNeeds' })
            cache.evict({ fieldName: 'students' })
        },
    })

    return (
        <ModalView
            onClose={onClose}
            ContentComponent={
                <Column spacing={6}>
                    <SectionTitle title={i18n._(t`Deelnemer verwijderen`)} heading="H4" />
                    <Paragraph>
                        {i18n._(
                            t`Weet je zeker dat je deelnemer ${studentName} wilt verwijderen? 
                                Alle leervragen, verwijzingen, dossier onderdelen en documenten van 
                                deze deelnemer zullen ook verwijderd worden.`
                        )}
                    </Paragraph>
                </Column>
            }
            BottomComponent={
                <>
                    <Button type={ButtonType.secondary} onClick={onClose} disabled={loading}>
                        {i18n._(t`Annuleren`)}
                    </Button>
                    <Button
                        danger={true}
                        type={ButtonType.primary}
                        icon={IconType.delete}
                        onClick={handleDelete}
                        loading={loading}
                    >
                        {i18n._(t`Verwijderen`)}
                    </Button>
                </>
            }
        />
    )

    async function handleDelete() {
        try {
            await deleteStudentMutation({ variables: { input: { id: studentId } } })

            NotificationsManager.success(
                i18n._(t`Deelname is verwijderd`),
                i18n._(t`Je wordt doorgestuurd naar het overzicht`)
            )

            onDeleteSuccess()
        } catch (error: any) {
            if (!error.data) {
                NotificationsManager.error(i18n._(t`Actie mislukt`), i18n._(t`Er is een onverwachte fout opgetreden`))
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }
    }
}
