import { ParticipantsUpdateIntakeContainer } from 'components/Participants/components/ParticipantsUpdateContainer/ParticipantsUpdateContainer'
import { SessionContext } from 'components/Providers/SessionProvider/context'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { TaalhuisParticipantsDetailRouteParams, taalhuisRoutes } from 'routes/taalhuis/taalhuisRoutes'

export const ParticipantsUpdateIntakeView: React.FunctionComponent = () => {
    const { taalhuisParticipantId } = useParams<TaalhuisParticipantsDetailRouteParams>()
    const sessionContext = useContext(SessionContext)
    const { organizationSlug } = sessionContext

    const baseRoot = taalhuisRoutes(organizationSlug)

    return <ParticipantsUpdateIntakeContainer routes={baseRoot} studentId={taalhuisParticipantId} />
}
